
<div class="app">
  <div class="auth_block">
    <div class="auth_content">
      <div class="auth_content_wrap">
        <!-- Header -->
        <header class="wrap_header">
          <div class="app_brand">
            <div class="brand_icon">
              <img src="../assets/img/isg_logo_2024.png" alt="" />
            </div>
            <div class="brand_text">
              <h1>INDIAN SOCIETY OF GASTROENTEROLOGY</h1>
            </div>
          </div>
        </header>
        <!-- Content -->
        <div class="wrap_content">

          <div class="page_title">
            <h2>Login</h2>
            <p>Please enter your details below</p>
          </div>

          <div class="auth_form">
            <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

            <div class="form_group">
              <label class="form_label required" for="emailInput">Email  </label>
              <input class="form_control" type="text" id="emailInput" placeholder="mail@website.com" formControlName="Email" />
              <div  *ngIf="formGroup.controls['Email'].invalid && formGroup.controls['Email'].dirty">
              
                <span class="error" *ngIf="formGroup.controls['Email'].errors.required">{{'login.loginForm.email.validation.required' | translate }}</span>
                <span  class="error" *ngIf="formGroup.controls['Email'].errors.pattern && !formGroup.controls['Email'].errors.maxlength && !formGroup.controls['Email'].errors.minlength">{{'login.loginForm.email.validation.invalid' | translate }}</span>
                <span  class="error" *ngIf="formGroup.controls['Email'].errors.maxlength">{{'login.loginForm.email.validation.maxlength' | translate }}</span>
                <span  class="error" *ngIf="formGroup.controls['Email'].errors.minlength">{{'login.loginForm.email.validation.minlength' | translate }}</span>
              </div>

            </div>
            <div class="form_group">
              <label class="form_label required" for="passwordInput">Password</label>
              <input class="form_control" type="password" id="emailInput" placeholder="************" formControlName="Password"/>
              <div *ngIf="formGroup.controls['Password'].invalid && formGroup.controls['Password'].dirty">
                <span class="error" *ngIf="formGroup.controls['Password'].errors.required">{{'login.loginForm.password.validation.required' | translate }}</span>
                <span class="error" *ngIf="formGroup.controls['Password'].errors.maxlength">{{'login.loginForm.password.validation.maxlength' | translate }}</span>
              </div>
            </div>
            
            <div class="form_group form_flex space_between">
              <!-- Remember me -->
              <!-- <div class="custom_checkbox">
                <input class="inp-cbx" id="cbx" type="checkbox">
                <label class="cbx" for="cbx">
                    <span>
                        <svg width="10px" height="8px" viewBox="0 0 12 10">
                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                    </span>
                    <span class="cbx_txt">Remember me</span>
                </label>
              </div> -->
              <!--/. Remember me -->

              <!-- Forgot password -->
              <!-- <div class="forgot_password">
                <a href="javascript:void(0)">Forgot password?</a>
              </div> -->
              <!--/. Forgot password -->
            </div>
            
            <div class="form_group">
              <button type="submit" class="secondary_btn width_100">Login</button>
            </div>
            </form>
          </div>

        </div>
        
        <!-- Footer -->
        <footer class="wrap_footer">
          <p class="copyright">©ISGCON 2024 - All rights reserved.</p>
          <div class="powered_by">
            <p>
              Powered by 
              <a href="#" target="_blank">
                <img src="../assets/img/Communa-Logo.png" alt="" />
              </a>
            </p>
          </div>
        </footer>
      </div>
    </div>
    <div class="auth_right_panel">
        <div class="right_panel_wrap">
          <div class="right_panel_content">
            <h1>Abstract Review System</h1>
          </div>
        </div>
    </div>
  </div>
</div>



